<template>
  <div>
    <router-view />
  </div>
  
</template>

<script>


export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}


.start-message {
  font-size: 1.5rem; /* Adjust size as needed */
  color: white; /* Adjust color as needed */
  margin: 1rem; /* Adjust margin as needed */
}

@keyframes stutterBorder {
  0% {
    border-color: rgb(255, 153, 0);
    border-width: 1px;
    border-style: solid;
    box-shadow: none;
    transform: none;
  }
  10% {
    border-left-color: rgba(255, 0, 0, 0.5); /* Left border starts fading */
    border-width: 2px;
    border-style: solid dashed solid solid;
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.6), 0 0 10px rgba(0, 255, 0, 0.3);
  }
  20% {
    border-right-color: rgba(255, 255, 255, 0); /* Right border disappears */
    border-left-color: rgba(255, 100, 0, 0.8); /* Left border strengthens */
    border-width: 3px;
    border-style: solid dashed none solid;
    box-shadow: none;
  }
  30% {
    border-top-color: rgba(255, 50, 0, 0.5); /* Top border fades */
    border-left-color: rgba(0, 255, 0, 0.8);
    border-width: 1px;
    border-style: dotted solid solid dashed;
    box-shadow: 0 0 8px rgba(0, 255, 0, 0.7), 0 0 15px rgba(255, 0, 255, 0.4);
  }
  40% {
    border-bottom-color: rgba(255, 255, 255, 0); /* Bottom border disappears */
    border-top-color: rgba(255, 153, 0, 1); /* Top border restores */
    border-width: 2px;
    box-shadow: none;
    
  }
  50% {
    border-top-color: rgba(0, 0, 255, 0.5); /* Top border fades again */
    border-left-width: 5px; /* Left border becomes thicker */
    border-right-width: 1px; /* Right border thins */
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.8), 0 0 15px rgba(255, 255, 0, 0.5);
  }
  60% {
    border-left-color: rgba(255, 255, 255, 0); /* Left border disappears */
    border-right-color: rgba(255, 153, 0, 1); /* Right border restores */
    border-bottom-color: rgba(0, 255, 0, 1); /* Bottom border restores */
    border-width: 1px;
    box-shadow: none;
    transform: none;
  }
  70% {
    border-left-color: rgba(0, 0, 0, 0.1); /* Left border barely visible */
    border-right-color: rgba(255, 0, 0, 0.8); /* Right border glitches */
    border-top-color: rgba(255, 255, 255, 0); /* Top border disappears */
    border-bottom-width: 3px;
    box-shadow: 0 0 5px rgba(0, 255, 255, 0.6), 0 0 20px rgba(255, 0, 0, 0.8);
  }
  80% {
    border-color: rgb(255, 153, 0);
    border-top-color: rgba(255, 255, 255, 0); /* Top border still invisible */
    border-width: 1px;
    box-shadow: none;
  }
  90% {
    border-top-width: 5px; /* Top border reappears with larger width */
    border-right-width: 3px; /* Right border thickens */
    border-left-color: rgba(255, 50, 0, 0.5); /* Left border glitches */
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.9), 0 0 25px rgba(255, 0, 255, 0.6);
  }
  100% {
    border-color: rgb(255, 153, 0);
    border-width: 1px;
    border-style: solid;
    box-shadow: none;
    transform: none;
  }
}


@keyframes glitchImage {
  0% {
    filter: invert(1) brightness(1) sepia(1) saturate(800%) opacity(75%);
    
  }
  10% {
    filter: invert(1) brightness(1) sepia(1)  saturate(700%) opacity(75%);
    
  }
  20% {
    filter: invert(1) brightness(1) sepia(1) saturate(500%) opacity(75%);
   
  }
  30% {
    filter: invert(1) brightness(1) sepia(1) saturate(560%) opacity(75%);
    
  }
  40% {
    filter: invert(1) brightness(1) sepia(1)  saturate(400%) opacity(75%);
    
  }
  50% {
    filter: invert(1) brightness(1) sepia(1) saturate(300%) opacity(75%);
    
  }
  60% {
    filter: invert(1) brightness(1) sepia(1) saturate(700%) opacity(75%);
    
  }
  70% {
    filter: invert(1) brightness(1) sepia(1)  saturate(800%) opacity(75%);
    
  }
  80% {
    filter: invert(1) brightness(1) sepia(1) saturate(500%) opacity(75%);
    
  }
  90% {
    filter: invert(1) brightness(1) sepia(1)  saturate(500%) opacity(75%);
    
  }
  100% {
    filter: invert(1) brightness(1) sepia(1)  saturate(700%) opacity(75%);
    
  }
}


body {
      background-color: black;
      color: lime;
      font-family: 'Courier New', Courier, monospace;
      margin: 0;
      padding: 20px;
  }
  
  .terminal-wrap {
      border: 1px solid rgb(255, 153, 0);
      border-top: 0px;

      padding: 10px;
      width: 100%;
      height: 100%; /* Full height of the terminal */
      overflow-y: auto;
      font-family: monospace; /* Typical terminal font */
      white-space: pre-wrap; /* Preserve white space and line breaks */
      animation: stutterBorder 15s infinite;
  }

  .terminal-small {
      border: 1px solid rgb(255, 153, 0);
      padding: 30px;
      width: 94%;
      height: 600px; /* Full height of the terminal */
      overflow-y: auto;
      font-family: monospace; /* Typical terminal font */
      white-space: pre-wrap; /* Preserve white space and line breaks */
      animation: stutterBorde 3s infinite;
      transition: all 0.3s ease;
  }


  .warhammer-btn {
  background-color: #111; /* Dark background for grimdark effect */
  border: 2px solid #5a5a5a; /* Metallic, worn-out border */
  color: #b0ff00; /* Bright green text for futuristic glow */
  text-transform: uppercase; /* For a strong, militaristic feel */
  letter-spacing: 2px; /* Slight spacing for digital effect */
  font-family: 'Orbitron', sans-serif; /* Retro-futuristic font */
  font-size: 14px;
  padding: 12px 24px;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 10px rgba(0, 255, 0, 0.6), 0 0 15px rgba(0, 255, 0, 0.4); /* Glowing inset shadow */
}

.warhammer-btn::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border: 2px solid rgba(176, 255, 0, 0.6); /* Glowing green border */
  transition: all 0.3s ease;
  box-shadow: 0 0 8px rgba(176, 255, 0, 0.6);
}

.warhammer-btn:hover {
  background-color: #1f1f1f; /* Slightly lighter background on hover */
  color: #fff;
  box-shadow: inset 0 0 15px rgba(176, 255, 0, 0.8), 0 0 20px rgba(176, 255, 0, 0.5);
}

.warhammer-btn:active {
  background-color: #000;
  border-color: #b0ff00;
  box-shadow: inset 0 0 20px rgba(176, 255, 0, 1);
}


.terminal-large {
  border: 1px solid rgb(255, 153, 0);
  padding: 30px;
  width: 94%;
  height: 1600px; /* Full height of the terminal */
  overflow-y: auto;
  font-family: monospace; /* Typical terminal font */
  white-space: pre-wrap; /* Preserve white space and line breaks */
  animation: stutterBorde 3s infinite;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
}
  
  .output {
  text-align: left; /* Align text to the left */
  display: block; /* Ensures it's a block container */
   /* Ensure text aligns to the baseline */
}
  .text-orange {
    color: rgb(255, 153, 0);
  }
  .text-red {
    color: rgb(218, 33, 33);
  }
  .text-green {
    color: rgb(4, 196, 4);
  }

  .aquila-filter {
    animation: glitchImage 3s infinite; /* Adjust duration and timing as needed */
    filter: invert(1) brightness(1) sepia(1) saturate(500%) opacity(75%);
  }


  @keyframes glitchImageInner {
  0% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(5000%);
    transform: none;
  }
  10% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(10000%);
    
  }
  20% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(5000%);
   
  }
  30% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(9560%);
    transform: translate(-1px, -1px) rotate(1deg);
  }
  40% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(1000%);
    transform: none;
  }
  50% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(1000%);
    
  }
  60% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(1000%);
    transform: none;
  }
  70% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(1000%);
    transform: translate(-1px, -1px) rotate(1deg);
  }
  80% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(1000%);
    
  }
  90% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(9560%);
    transform: translate(-1px, -1px) rotate(1deg);
  }
  100% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(10000%);
    transform: none;
  }
}

@keyframes glitchImagePhoto {
  0% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(700%);

  }
  10% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(1000%);
    
  }
  20% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(800%);
   
  }
  30% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(900%);

  }
  40% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(1000%);

  }
  50% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(1000%);
    
  }
  60% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(1400%);

  }
  70% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(1000%);

  }
  80% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(800%);
    
  }
  90% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(2060%);

  }
  100% {
    filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(1500%);

  }
}


  /* Add your CSS styles here */
  .green-image {
    animation: glitchImageInner 2.5s infinite; /* Adjust duration and timing as needed */
  filter: invert(0) brightness(1) sepia(1) hue-rotate(90deg) saturate(10000%);
}




@keyframes glitchIn {
  0% {
    clip-path: inset(100% 0 0 0); /* Image is hidden at the start */
    filter: brightness(200%) contrast(0%) blur(40px); /* Start with brightness and blur */
    opacity: 0.2;
  }
  5% {
    clip-path: inset(90% 0 0 0); /* Revealing a bit */
    transform: scaleY(1.1) skewX(-20deg) translateX(20px); /* Sudden jump in skew/translate */
    filter: brightness(150%) contrast(50%) blur(5px);
    opacity: 0.5;
  }
  10% {
    clip-path: inset(85% 0 0 0); 
    transform: scaleY(0.8) skewX(15deg) translateX(-15px); /* Shift back */
    filter: brightness(100%) contrast(100%) blur(3px);
    opacity: 0.7;
  }
  20% {
    clip-path: inset(70% 0 0 0); /* More reveal */
    filter: brightness(200%) contrast(80%) blur(2px);
    opacity: 0.8;
  }
  30% {
    clip-path: inset(50% 0 0 0); /* Reveal more */
    transform: none;
    filter: brightness(150%) contrast(100%) blur(1px); /* Decrease blur */
    opacity: 0.9;
  }
  40% {
    clip-path: inset(25% 0 0 0); /* Reveal most */
    transform: translateX(2px) skewX(2deg); /* Small glitch */
    filter: brightness(100%) contrast(100%) blur(1px); 
    opacity: 0.95;
  }
  50% {
    clip-path: inset(10% 0 0 0); /* Almost fully revealed */
    transform: translateX(-2px) skewX(-1deg); /* Small glitch */
    filter: brightness(120%) contrast(100%) blur(0.5px); 
    opacity: 1;
  }
  60% {
    clip-path: inset(0% 0 0 0); /* Fully revealed */
    transform: none;
    filter: brightness(90%) contrast(100%) blur(0); /* Reduce to clear */
    opacity: 1;
  }
  70% {
    transform: translateY(-1px) skewX(1deg); /* Small glitch flicker */
    filter: brightness(110%);
  }
  80% {
    transform: translateY(1px) skewX(-1deg);
    filter: brightness(105%);
  }
  90% {
    transform: none; /* Return to stable */
    filter: brightness(100%);
  }
  100% {
    clip-path: none;
    transform: none;
    filter: none;
    opacity: 1;
  }
}

@keyframes flicker {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6; /* Brief flicker */
  }
  75% {
    opacity: 0.8; /* Second flicker */
  }
}


img.glitchy {
  animation: glitchIn 4s ease-in-out forwards, /* Adjust timing for slower/faster effect */ glitchImagePhoto 2.5s infinite, flicker 1.5s infinite;
}



</style>
