<template>
    <div class="flex flex-col items-center terminal-wrap" @dblclick="setFastMode">
      <img class="aquila-filter" src="../assets/aquila.png" alt="">
      <div :class="terminalSize === 'large' ? 'terminal-large' : 'terminal-small'">
        <img class="w-1/4 green-image" src="../assets/aquila-termi.png" alt="">
  
        <div v-if="showStartMessage" class="start-message">
          <button class="btn warhammer-btn" @click="startClick">APPUYEZ SUR ENTREE OU CLIQUEZ POUR COMMENCER <br>
            (Attention, il y a du son)</button>
        
        </div>
  
        
        
        <div class="flex justify-around mt-8 mb-8" v-if="showButtons">
          <button class="btn warhammer-btn" @click="showChar">DOSSIER PERSONNEL</button>
      </div>
      <div class="image-container overflow-hidden w-1/5" v-if="showPhoto">
    <img class="glitchy mt-5 ml-3 rounded-lg" src="../assets/gallius_graum.png" alt="">
  </div>
        <div class="output flex flex-col justify-start" ref="output" v-html="displayedText"></div>
      
      </div>
      </div>
    </template>
    
    <script>
    export default {
      data() {
        return {
          commands: [
            'Bienvenue, Gallius Graum',
            "Niveau d'accès : __[ADEPTUS_MINISTRATUM]__",
            "Localisation : TELLERAH'S PRIME",
            '[ID : #G54G6B321]',
            '',
            'skip',
            "PAS DE NOUVEAUX MESSAGES.",
            "",
            "skip",
            "<div class='flex'>GLOIRE AU DIEU MACHINE, <span class='text-red'>GLOIRE A L'OMNIMESSIE.</span></div>",
            "",
            "",
            'skip',
            '',
          ],
          showStartMessage: true, // New property to control the start message
          currentCommandIndex: 0,
          currentCharIndex: 0,
          displayedText: '',
          showButtons: false,
          typingSpeed: 25,    // Typing speed in milliseconds
          commandDelay: 500,   // Delay between commands in milliseconds
          showPhoto: false,
          terminalSize: 'small', // default size of terminal
          typeSound: new Audio(require('../assets/type_sound.mp3')),
          clickSound: new Audio(require('../assets/button_click.wav')),
          isSoundPlaying: false, // New property to track sound playing state
          playSound: true, // Flag to indicate whether to play sounds
          imp_appear: new Audio(require('../assets/img_appear.mp3'))
          
        };
      },
      methods: {
        typeCommand() {
      if (this.currentCommandIndex < this.commands.length) {
        // Clear displayed text for specific commands
        if (this.commands[this.currentCommandIndex].includes("skip")) {
          this.displayedText = ''; // Clear text when reaching the command
          this.currentCommandIndex++; // Move to the next command
          this.currentCharIndex = 0; // Reset character index
          setTimeout(this.typeCommand, this.commandDelay); // Delay before typing the next command
          return; // Exit the function to prevent further processing
        }
        
  
        if (this.currentCharIndex < this.commands[this.currentCommandIndex].length) {
          const currentChar = this.commands[this.currentCommandIndex][this.currentCharIndex];
  
          // Check if the current character is not a space, HTML tag, or skip command
          if (currentChar.trim() !== '' && this.playSound &&
              !this.commands[this.currentCommandIndex].includes("skip") && 
              !currentChar.startsWith("<") && 
              !currentChar.endsWith(">") &&
            currentChar !== ' ' && currentChar !== "/" && currentChar !== 'd' && currentChar !=='i' && currentChar!=='v' 
            && currentChar !=='s' && currentChar !=='p' && currentChar !=='a' && currentChar !=='n' && currentChar!=="b" && currentChar!=="r") 
               {
  
                if (!this.isSoundPlaying) {
                this.isSoundPlaying = true; // Set flag to true
                this.typeSound.currentTime = 0; // Reset the audio to the beginning
                this.typeSound.play(); // Play the sound
  
                // Reset the flag when the sound ends
                this.typeSound.onended = () => {
                  this.isSoundPlaying = false; // Set flag back to false when the sound ends
                };
              }
          }
  
          // Append the current character to displayed text
          this.displayedText += currentChar;
  
          this.currentCharIndex++;
          setTimeout(this.typeCommand, this.typingSpeed); // Adjust the typing speed (in milliseconds)
        } else {
          // Move to the next command after a delay
          this.currentCharIndex = 0;
          this.currentCommandIndex++;
          if (this.currentCommandIndex === this.commands.length) {
            // When the last command is reached, show the buttons
            this.showButtons = true;
          }
          setTimeout(() => {
            this.displayedText += '<br>'; // Add a newline after each command
            this.typeCommand(); // Start typing the next command
          }, this.commandDelay); // Delay before typing the next command
        }
        }
        },
        setFastMode() {
        this.typingSpeed = 1;
        this.commandDelay = 1;
        this.isSoundPlaying = false; // Ensure sound is not playing
        this.playSound = false; // Flag to indicate sound should not play
      },
      showChar() {
        this.showPhoto = true;
        this.imp_appear.play();
        this.displayedText = '';
        this.typingSpeed = 35;
        this.commandDelay = 500;
        this.playSound = true;
        this.clickSound.play();
        this.commands = [
          'Nom : Gallius Graum',
          'Âge : 27 années Terranes',
          'Groupe sanguin : B+',
          'Matricule : #G54G6B321',
          'Rang : Gouverneur planétaire ',
          "Niveau d'accès : __[ADEPTUS_ADMINISTRATUM]__",
          '<div class="flex">Statut :<span class="text-red"> MORT.<span> </div>',
          "Lieu du décès : Tellerah's prime",
          '<div class="flex">Hérétique :<span class="text-green"> NON<span> </div>',
          'Commentaires : Aucun.',
          "Fautes : ",
          'Aucunes.'
          
          
        ];
        this.terminalSize = 'large'; // Adjust terminal size here
      this.currentCommandIndex = 0;
      this.currentCharIndex = 0;
      setTimeout(() => {
  this.typeCommand();
}, 1000); // Adjust the delay duration (in milliseconds) as needed
  
      },
      
      startClick() {
          this.typeCommand(); // Call startTyping on Enter key press
          this.showStartMessage = false;
          window.removeEventListener('keydown', this.handleKeyPress); // Clean up event listener
  
      },
      handleKeyPress(event) {
        if (event.key === 'Enter') {
          this.typeCommand(); // Call startTyping on Enter key press
          this.showStartMessage = false;
          this.ambiant_base.play();
          this.ambiant_loud.play();
          window.removeEventListener('keydown', this.handleKeyPress); // Clean up event listener
        }
      
    },
},
      
      
      
    mounted() {
      window.addEventListener('keydown', this.handleKeyPress); // Add key event listener
    },
}
    
    </script>
    
    <style>
  
  
  
    </style>