import { createRouter, createWebHistory } from 'vue-router';
import vindicareChar from '../views/vindicare-char.vue'; // Example view component
import kasrkinChar from '../views/kasrkin-char.vue';
import psykerChar from '@/views/psyker-char.vue';

import flamerChar from '@/views/flamer-char.vue';
import priestChar from '@/views/priest-char.vue';
import galliusGraum from '@/views/gallius-graum.vue';

const routes = [
    {
        path: '/V1ND2B',
        name: 'vindicare',
        component: vindicareChar,
    },
    {
        path: '/IMPe27B1C3D6A9',
        name: 'kasrkin',
        component: kasrkinChar,
    },

    {
        path: '/M1N78ST679TR1',
        name: 'priest',
        component: priestChar,
    },
    {
        path: '/IMPe27B36P3S5Y1',
        name: 'psyker',
        component: psykerChar,
    },
    {
        path: '/IMPe26B8964T2M6T',
        name: 'flamer',
        component: flamerChar,
    },

    {
        path: '/G54G6B321',
        name: 'gGraum',
        component: galliusGraum,
    },

    // Add more routes as needed
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;